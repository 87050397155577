.be-drawer {
  background-color: #3c3a34 !important;
  display: flex;
}
.color-overlay {
  min-height: 810px;
  background: rgba(55, 76, 93, 0.85);
}
@media only screen and (min-width: 40.063em) {
  .color-overlay {
    min-height: 700px;
  }
}
@media only screen and (min-width: 64.063em) {
  .color-overlay {
    min-height: 900px;
  }
}

.drawer-position,
.drawer-effect {
  margin-bottom: 50px;
  text-align: center;
}

input[type='radio'] {
  -webkit-appearance: radio;
}
.toggle__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 20px;
}

.toggle__text-label {
  display: inline-block;
  font-weight: 200;
  font-size: 14px;
}

.toggle__text-label:hover {
  cursor: pointer;
}

/* Hide the checked box - only used for it's 'checked' property */
.toggle__input {
  display: none;
}

.toggle__input:checked ~ .toggle:before {
  left: 2rem;
}

.toggle__input:checked ~ .toggle {
  background-color: #3fc380;
}

.toggle:before {
  background-color: #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.2);
  content: '';
  display: block;
  left: 0.15rem;
  height: 1.2rem;
  position: absolute;
  width: 1.2rem;
  transition: 0.3s cubic-bezier(0.54, 1.7, 0.5, 1);
  z-index: 10;
}

.toggle {
  background-color: #f1f1f1;
  border-radius: 1.25rem;
  box-shadow: inset 0 0.15rem 0.25rem rgba(0, 0, 0, 0.25);
  box-sizing: content-box;
  display: block;
  height: 1.2rem;
  padding: 0.15rem;
  position: relative;
  transition: background-color 0.3s ease-in;
  transition-delay: 0.1s;
  width: 3.09rem;
}

.toggle:hover {
  cursor: pointer;
}

.toggle__input:checked ~ .toggle .toggle__track:after {
  color: #ffffff;
  text-shadow: none;
}

.radios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}
.radios label {
  cursor: pointer;
  font-weight: 200;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radios input + label {
  border-radius: 50%;
  border: 2px solid;
  width: 20px;
  height: 20px;
  margin-left: 2px;
  margin-right: 2px;
  transition: 0.3s ease;
  position: relative;
}

.radios input + label:before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: transparent;
  transition: 0.3s ease;
  position: absolute;
}

.radios input:checked + label {
  color: #ef5e1b;
}

.radios input:checked + label:before {
  background: #ef5e1b;
}

.columns p {
  max-width: 380px;
  padding-left: 50px;
  font-size: 13px;
  font-weight: 300;
  color: #9e9e9e;
  margin-bottom: 20px;
}
.columns li .file-btn {
  color: #fff;
  background: #3f4197;
  padding: 4px;
  border-radius: 8px;
  width: 200px;
  margin-left: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  overflow: hidden;
}
input[type='file'] {
  /* display: none; */
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  width: 200px;
  cursor: pointer;
}

.columns li .file-btn:disabled,
.columns li .file-btn[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

#defaultWallpaper {
  background: #ef5e1b;
  padding: 4px;
  border-radius: 8px;
  width: 290px;
  margin-left: 50px;
  margin: 30px 0px 20px 20px;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row.collapse .columns {
  /* padding-left: 30px; */
  padding-right: 0;
  margin-top: 50px;
}
.row.collapse h2 {
  /* margin-left: 120px; */
  margin-top: 36px;
}
.columns h4 {
  margin-bottom: 20px;
}
.disabled {
  opacity: 0.5;
}
.panel {
  clear: left;
  color: #fff;
  background: #d95843;
  padding: 5px 10px;
  border: 1px solid #f48e44;
  border-radius: 3px;
}

/**************
    ## Container ##
    **************/
.be-container {
  position: relative;
  -webkit-overflow-scrolling: touch;
}
/************
    ## Toggler ##
    ************/
.be-toggle {
  left: -9999px;
  position: absolute;
  top: -9999px;
}
.be-toggle:focus ~ .be-toggle-label {
  border-color: #ef5e1b;
  color: #ef5e1b;
}
.be-toggle-label {
  display: none;
  cursor: pointer;
  display: block;
  position: fixed;
  top: 15px;
  z-index: 99;
  color: #f48e44;
  width: 50px;
  height: 50px;
  transition: all 400ms ease-in-out;
  border: 2px solid #f48e44;
  border-radius: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.be-toggle-label:hover {
  border-color: #ef5e1b;
  color: #ef5e1b;
}
.be-toggle-label:hover .be-toggle-icon,
.be-toggle-label:hover .be-toggle-icon:before,
.be-toggle-label:hover .be-toggle-icon:after {
  background-color: #ef5e1b;
}
.be-toggle-label:active {
  -webkit-tap-highlight-color: transparent;
}
.be-toggle-label .be-toggle-icon,
.be-toggle-label .be-toggle-icon:before,
.be-toggle-label .be-toggle-icon:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  width: 24px;
  cursor: pointer;
  background: #f48e44;
  display: block;
  content: '';
  transition: all 280ms ease-in-out;
}
.be-toggle-label .be-toggle-icon {
  transform: translate3d(-50%, -4px, 0);
  -webkit-transform: translate3d(-50%, -4px, 0);
}
.be-toggle-label .be-toggle-icon:before {
  transform: translate3d(-50%, -14px, 0);
  -webkit-transform: translate3d(-50%, -14px, 0);
}
.be-toggle-label .be-toggle-icon:after {
  transform: translate3d(-50%, 10px, 0);
  -webkit-transform: translate3d(-50%, 10px, 0);
}
.be-toggle-label[data-toggle-label='left'] {
  right: 15px;
  left: auto;
}
.be-toggle-label[data-toggle-label='right'] {
  right: 28px;
  left: auto;
}
.be-toggle-label[data-toggle-label='top'] {
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label:not([data-toggle-label='left']),
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label:not([data-toggle-label='right']),
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label:not([data-toggle-label='top']) {
  opacity: 0;
  z-index: -1;
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left'],
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right'],
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top'] {
  border-color: #ef5e1b;
  color: #ef5e1b;
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left']
  .be-toggle-icon,
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right']
  .be-toggle-icon,
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top']
  .be-toggle-icon {
  background-color: transparent;
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left']
  .be-toggle-icon:before,
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left']
  .be-toggle-icon:after,
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right']
  .be-toggle-icon:before,
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right']
  .be-toggle-icon:after,
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top']
  .be-toggle-icon:before,
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top']
  .be-toggle-icon:after {
  top: 0;
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left']
  .be-toggle-icon:before,
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right']
  .be-toggle-icon:before,
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top']
  .be-toggle-icon:before {
  transform: translateX(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) rotate(45deg);
}
.be-toggle[data-toggle='left']:checked
  ~ .be-toggle-label[data-toggle-label='left']
  .be-toggle-icon:after,
.be-toggle[data-toggle='right']:checked
  ~ .be-toggle-label[data-toggle-label='right']
  .be-toggle-icon:after,
.be-toggle[data-toggle='top']:checked
  ~ .be-toggle-label[data-toggle-label='top']
  .be-toggle-icon:after {
  transform: translateX(-50%) translateY(-10px) rotate(-45deg);
  -webkit-transform: translateX(-50%) translateY(-10px) rotate(-45deg);
  top: 10px;
}
/***********
    ## Drawer ##
    ***********/
.be-drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  visibility: hidden;
  background-color: #3c3a34;
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: ease-out;
  width: 100%;
}
@media only screen and (min-width: 40.063em) {
  .be-drawer {
    width: 500px;
  }
}
@media only screen and (min-width: 64.063em) {
  .be-drawer {
    width: 500px;
  }
}
.be-drawer[data-position='right'] {
  left: auto;
  right: 0;
}
.be-drawer[data-position='top'] {
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 40.063em) {
  .be-drawer[data-position='top'] {
    height: 100%;
  }
}
@media only screen and (min-width: 64.063em) {
  .be-drawer[data-position='top'] {
    height: 100px;
  }
}

/*******************
    ## Pusher Overlay ##
    *******************/
.be-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: opacity;
  transition-duration: 500ms;
  transition-delay: 500ms;
  transition-timing-function: ease-in-out;
}
.be-toggle[data-toggle='left']:checked ~ .be-overlay[data-overlay='left'] {
  right: 100%;
}
@media only screen and (min-width: 40.063em) {
  .be-toggle[data-toggle='left']:checked ~ .be-overlay[data-overlay='left'] {
    right: 300px;
  }
}
@media only screen and (min-width: 64.063em) {
  .be-toggle[data-toggle='left']:checked ~ .be-overlay[data-overlay='left'] {
    right: 300px;
  }
}
.be-toggle[data-toggle='right']:checked ~ .be-overlay[data-overlay='right'] {
  left: 100%;
}
@media only screen and (min-width: 40.063em) {
  .be-toggle[data-toggle='right']:checked ~ .be-overlay[data-overlay='right'] {
    left: 300px;
  }
}
@media only screen and (min-width: 64.063em) {
  .be-toggle[data-toggle='right']:checked ~ .be-overlay[data-overlay='right'] {
    left: 300px;
  }
}
.be-toggle[data-toggle='top']:checked ~ .be-overlay[data-overlay='top'] {
  top: 100%;
}
@media only screen and (min-width: 40.063em) {
  .be-toggle[data-toggle='top']:checked ~ .be-overlay[data-overlay='top'] {
    top: 100%;
  }
}
@media only screen and (min-width: 64.063em) {
  .be-toggle[data-toggle='top']:checked ~ .be-overlay[data-overlay='top'] {
    top: 100px;
  }
}
.be-toggle[data-toggle='left']:checked ~ .be-overlay[data-overlay='left'],
.be-toggle[data-toggle='right']:checked ~ .be-overlay[data-overlay='right'],
.be-toggle[data-toggle='top']:checked ~ .be-overlay[data-overlay='top'] {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
}
/********************************
    ## Fallback for older browsers ##
    ********************************/

/*****************************
    ## Effect - Slide in on top ##
    *****************************/
/* DRAWER */
[data-effect='be-effect-slide'] .be-drawer {
  z-index: 3;
  transition-duration: 300ms;
}
[data-effect='be-effect-slide'] .be-drawer[data-position='left'] {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
[data-effect='be-effect-slide'] .be-drawer[data-position='right'] {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
[data-effect='be-effect-slide'] .be-drawer[data-position='top'] {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
[data-effect='be-effect-slide']
  .be-toggle[data-toggle='left']:checked
  ~ .be-drawer[data-position='left'],
[data-effect='be-effect-slide']
  .be-toggle[data-toggle='right']:checked
  ~ .be-drawer[data-position='right'],
[data-effect='be-effect-slide']
  .be-toggle[data-toggle='top']:checked
  ~ .be-drawer[data-position='top'] {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
/* OVERLAY */
[data-effect='be-effect-slide'] .be-overlay {
  transition-duration: 500ms;
  transition-delay: 250ms;
}
/********************
    ## Effect - Reveal ##
    ********************/
/* DRAWER */
[data-effect='be-effect-reveal'] .be-drawer {
  visibility: visible;
  transition-duration: 500ms;
}

/* OVERLAY */
[data-effect='be-effect-reveal'] .be-overlay {
  transition-duration: 500ms;
  transition-delay: 250ms;
}
/*********************
    ## Effect - Push ##
    *********************/
/* DRAWER */
[data-effect='be-effect-push'] .be-drawer {
  transition-duration: 500ms;
}
[data-effect='be-effect-push'] .be-drawer[data-position='left'] {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
[data-effect='be-effect-push'] .be-drawer[data-position='right'] {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
[data-effect='be-effect-push'] .be-drawer[data-position='top'] {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
[data-effect='be-effect-push']
  .be-toggle[data-toggle='left']:checked
  ~ .be-drawer[data-position='left'],
[data-effect='be-effect-push']
  .be-toggle[data-toggle='right']:checked
  ~ .be-drawer[data-position='right'],
[data-effect='be-effect-push']
  .be-toggle[data-toggle='top']:checked
  ~ .be-drawer[data-position='top'] {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* OVERLAY */
[data-effect='be-effect-push'] .be-overlay {
  transition-duration: 500ms;
  transition-delay: 250ms;
}
/* Drawer Tab styles */
.tabs {
  background-color: #161f1d;
}
.tab__content {
  position: relative;
  width: 100%;
  padding: 14px;
  overflow-y: auto;
}
.tabs .tab__link {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  text-decoration: none;
  color: white;
}
.tabs .tab__item--active .tab__link {
  background-color: #3c3a34;
  color: white;
}
.tab__pane {
  display: none;
}
.tab__pane--active {
  display: block;
}
.tabs {
  height: 100vh;
}
.tabs .tab__list {
  display: block;
  top: 6rem;
  position: relative;
}
.tabs .tab__item {
  height: 50px;
  width: 50px;
  background-color: #161f1d;
  text-align: center;
  display: flex;
}
.tabs .tab__item :hover {
  color: darkgrey;
}
.tabs .tab__item--active .tab__link:hover {
  color: white;
}

#add-shortcuts-form input[type='text'] {
  background-color: white;
  color: black;
  padding: 0.5rem 1.5rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
}
#add-shortcuts-form .form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
button#add-new-shortcut-button {
  background: #369d7d;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
}

#settings-stories-source {
  background-color: #ef5e1b;
  color: #f8ffff;
  border-radius: 8px;
  margin-left: 2rem;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
}
