.clock {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: #f3f4f642;

  .digital-clock {
    padding: 10px;
  }
  .analog-clock {
    background: #fff url(../assets/clock.svg) no-repeat center;
    background-size: 88%;
    transition: all 2.5s cubic-bezier(0.12, 1.03, 0.34, 1);
    border-radius: 50%;
    position: relative;
    // background: #fff;
    &:before {
      background: #000;
      border-radius: 50%;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 6%;
      height: 6%;
      z-index: 0;
    }
    &:after {
      width: 2%;
      height: 2%;
    }
    .minutes-container,
    .hours-container,
    .seconds-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .seconds-container {
      .seconds {
        border-radius: 200%/10%;
        height: 30%;
        left: 49.5%;
        top: 20%;
        width: 1%;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        background: red;
        position: absolute;
        z-index: 8;
      }
    }
    .minutes-container {
      .minutes {
        background: #000;
        position: absolute;
        border-radius: 150%/10%;
        width: 2%;
        height: 35%;
        left: 49%;
        top: 15%;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
      }
    }
    .hours-container {
      .hours {
        background: #000;
        position: absolute;
        border-radius: 85%/10%;
        width: 2%;
        height: 20%;
        left: 49%;
        top: 30%;
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
      }
    }
    // .dial {
    //   position: absolute;
    //   left: 50%;
    //   width: 50px;
    //   height: 50px;
    //   transform-origin: bottom left;
    //   &.seconds {
    //     border-left: solid 4px red;
    //   }
    //   &.minutes {
    //     border-left: solid 1px #000000;
    //   }
    //   &.hours {
    //     width: 35px;
    //     height: 35px;
    //     top: 15px;
    //     border-left: solid 2px #000;
    //   }
    // }
  }
}

.clock-widget-container {
}
